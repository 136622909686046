export const PARTY_TYPE = {
  flt: 1, // Khách lẻ,
  installment_partner: 7, // đối tác trả góp
};

export const FUNCTIONS = {
  import_buy: 1, // nhập mua
  import_orther: 5, // nhập khác
  import_transfer: 3, // nhập chuyển
  export_orther: 2, // xuất khác
  export_transfer: 4, // xuất chuyển
  payment_order: { id: 8, code: 'TTBH' }, // thanh toán bán hàng
  sale_receipt: 6, // bán hàng
  gift: 10, // Quà tặng
  import_refund: 11, // Nhập trả
  party: 12, // Khách hàng
  refund: 7, // Trả hàng
  blog: 13, // Khách hàng
};

export const STATUSES = {
  pending: 1,
  confirm: 2,
  success: 3,

  // status of sale receipt
  created_sale_receipt: 4, // Đã tạo phiếu
  pending_sale_receipt: 5, // Chờ duyệt
  confirm_sale_receipt: 6, // Duyệt
  reject_sale_receipt: 7, // Từ chối
  paying_sale_receipt: 8, // Đang thanh toán
  success_sale_receipt: 9, // Hoàn thành
  refund_sale_receipt: 10, // Đơn trả lại
  pending_export: 11, // Chờ xuất kho
  exported: 12, // Đã xuất kho
  success_payment: 16, // Đã thanh toán
  shipping: 18, // Đang giao hàng
  shipped: 19, // Đã giao hàng
  partial_refund: 20, // Trả hàng một phần
  all_refund: 21, // Trả hàng toàn bộ
  edit_by_super_admin: 22, // Chỉnh sửa phiếu bởi tài khoản super admin

  // status of payment
  pending_payment: 1, // Chờ duyệt
  approved_payment: 2, // Đã duyệt
  decline_payment: 3, // Từ chối
};

export const PAGES_ID = {
  internal_orgs: 1,
  employee: 2,
  categories: 3,
  parties: 4,
  type_parties: 14,
  type_CSKH: 15,
  history_CSKH: 16,
  blog: 5,
  import_warehouse: 11,
  export_warehouse: 12,
  tagerts: 17,
  order_sample: 18,
  list_order: 20,
  create_order: 19,
  gift_order: 21,
  refund_order: 22,
  payment_order: 23,
  contract: 25,
  good: 10,
  good_category: 9,
  file_managements: 26,
  commission: 33,
  commission_employee: 34,
  good_supermarket: 35,
  employee_report: 27,
  cost_management: 37,
  account_management: 6,
  role: 7,
  permissions: 8,
  create_supermarket: 38,
  group_supermarket: 43,
  payment_account: 44,
};

export const FOLDER_IMAGE = {
  user: '__employee_photos__',
  good: '__good_photos__',
  party_attachment: '__party_attachment__',
  payment_attachment: '__payment_attachment__',
  payment_photos: '__payment_photos__',
  file_managements: '__file_managements__',
  good_attachment: '__good_attachments__',
  blog_attachment: '__blog_attachments__',
  contract_attachment: '__contract_attachment__',
  cost_attachment: '__cost_attachment__',
  order_sample_attachments: '__order_sample_attachments__',
  sale_receipt_attachment: '__sale_receipt_attachment__',
  order_import_attachments: '__order_import_attachments__',
  order_export_attachments: '__order_export_attachments__',
  employee_attachment: '__employee_attachment__',
  supermarket_good_attachments: '__supermarket_good_attachments__',
};

export const FOLDER_FILES = {
  conclusionContract: '__contract_attachment__',
  share_sale_receipt: '__share__',
};

export const NOTIFICATION_STATUS = {
  unread: 0,
  read: 1,
  not_viewed: 0,
  viewed: 1,
};

export const CONTRACT_TYPES = {
  good: 1, // hợp đồng sản phẩm
  travel: 2, // hợp đồng du lịch
};

export const PAYMENT_TYPE = {
  debt: 1, // Thanh toán công nợ
  order: 2, // Thanh toán đơn hàng
};

export const COOKIE_TABLE_COLUMN = {
  good: 'goodColumns_v10',
  orderManage: 'orderManageColumns_v11',
  orderDetailGood: 'orderDetailColumns_v10',
  fileManagements: 'fileManagementColumns_v10',
  parties: 'partyColumns_v10',
  goodsInventories: 'goodsInventoriesColumns_v10',
  payment: 'paymentColumns_v10',
  debts: 'debtColumns_v11',
  reportGoodByWarehouse: 'reportGoodByWarehouseColumns_v10',
  reportGoodByEmployee: 'reportGoodByEmployeeColumns_v10',
  reportGoodByParty: 'reportGoodByPartyColumns_v10',
  internalOrganization: 'internalOrganizationColumns_v1',
  employees: 'employeeColumns_v1',
  partyTypes: 'partyTypeColumns_v1',
  groupSupermarkets: 'groupSupermarketColumns_v1',
  crCategories: 'crCategoryColumns_v1',
  customerCares: 'customerCareColumns_v1',
  targetMonths: 'targetMonthColumns_v1',
  giftOrders: 'giftOrderColumns_v1',
  sampleOrders: 'sampleOrderColumns_v1',
  supermarkets: 'supermarketColumns_v2',
  categoryGoods: 'categoryGoodColumns_v1',
  costCategories: 'costCategoryColumns_v1',
  commissions: 'commissionColumns_v1',
  workDailies: 'workDailyColumns_v1',
  importGenerals: 'importGeneralColumns_v1',
  importInventories: 'importInventoryColumns_v1',
  importWarehouses: 'importWarehouseColumns_v1',
  importTransfers: 'importTransferColumns_v1',
  importRefunds: 'importRefundColumns_v1',
  exportGenerals: 'exportGeneralColumns_v1',
  exportOthers: 'exportOtherColumns_v1',
  exportTransfers: 'exportTransferColumns_v1',
  contractGoods: 'contractGoodsColumns_v1',
  contractTravels: 'contractTravelColumns_v1',
  paymentAccounts: 'paymentAccountColumns_v1',
};

export const COOKIE_FILTER_FIELD = {
  good: 'good_filter_v1',
  orders: 'orders_filter_v1',
  updateInventory: 'update_inventory_filter_v1',
  inventory: 'inventory_filter_v1',
};

export const STATUS_CHECKBOX = {
  unchecked: 0,
  checked: 1,
};

export const EXPORT_TYPE_SALE_RECEIPT = {
  unavailablePrice: 0,
  noDiscount: 1,
  availablePrice: 2,
  salesReceiptExcel: 3,
};

export const GOODS_CLASSIFICATION = [
  {
    id: 1,
    name: 'Hàng chung',
    colorSub: '#919EAB',
    color: 'none',
  },
  {
    id: 2,
    name: 'Hàng độc quyền',
    colorSub: '#ff4757c2',
    color: '#eb3b5a',
  },
  {
    id: 3,
    name: 'Hàng mới sắp về',
    colorSub: '#2ed573d6',
    color: '#20bf6b',
  },
  {
    id: 4,
    name: 'Tạm hết hàng',
    colorSub: '#1e90ffc9',
    color: '#3867d6',
  },
  {
    id: 5,
    name: 'Hàng tạm dừng bán',
    colorSub: '#1e90ffc9',
    color: '#3867d6',
  },
  {
    id: 6,
    name: 'Hàng xuống chất lượng',
    colorSub: '#1e90ffc9',
    color: '#3867d6',
  },
  {
    id: 7,
    name: 'Ngừng kinh doanh',
    colorSub: '#1e90ffc9',
    color: '#3867d6',
  },
  {
    id: 8,
    name: 'Hàng Horeca',
    colorSub: '#1e90ffc9',
    color: 'none',
  },
  {
    id: 9,
    name: 'Hàng chiến lược',
    colorSub: '#1e90ffc9',
    color: 'none',
  },
  {
    id: 11,
    name: 'Clear',
    colorSub: '#919EAB',
    color: 'none',
    parent: 1,
  },
];

export const GOODS_CLASSIFICATION_OPTION = [
  {
    id: '',
    name: 'Tất cả',
    colorSub: '#919EAB',
    color: 'none',
  },
  {
    id: 1,
    name: 'Hàng chung',
    colorSub: '#919EAB',
    color: 'none',
  },
  {
    id: 9,
    name: 'Hàng chiến lược',
    colorSub: '#919EAB',
    color: 'none',
    parent: 1,
  },
  {
    id: 3,
    name: 'Hàng mới sắp về',
    colorSub: '#2ed573d6',
    color: '#20bf6b',
    parent: 1,
  },
  {
    id: 4,
    name: 'Tạm hết hàng',
    colorSub: '#1e90ffc9',
    color: '#3867d6',
    parent: 1,
  },
  {
    id: 5,
    name: 'Hàng tạm dừng bán',
    colorSub: '#1e90ffc9',
    color: '#3867d6',
    parent: 1,
  },
  {
    id: 6,
    name: 'Hàng xuống chất lượng',
    colorSub: '#1e90ffc9',
    color: '#3867d6',
    parent: 1,
  },
  {
    id: 7,
    name: 'Ngừng kinh doanh',
    colorSub: '#1e90ffc9',
    color: '#3867d6',
    parent: 1,
  },
  {
    id: 8,
    name: 'Hàng Horeca',
    colorSub: '#919EAB',
    color: 'none',
    parent: 1,
  },
  {
    id: 11,
    name: 'Clear',
    colorSub: '#919EAB',
    color: 'none',
    parent: 1,
  },
  {
    id: 10,
    name: 'Khác',
    colorSub: '#919EAB',
    color: 'none',
    parent: 1,
  },
  {
    id: 2,
    name: 'Hàng độc quyền',
    colorSub: '#ff4757c2',
    color: '#eb3b5a',
  },
];

export const ORDER_TYPE_OPTION = [
  {
    id: 0,
    name: 'Đơn hàng bán',
  },
  {
    id: 1,
    name: 'Đơn hàng tặng',
  },
];

export const DATE_TYPE_OPTION = [
  {
    id: 'created',
    name: 'Ngày tạo đơn',
  },
  {
    id: 'expiry_date',
    name: 'Hạn giao hàng',
  },
  {
    id: 'delivery_date',
    name: 'Ngày giao hàng',
  },
];

export const TYPE_ORDER_CREATE = {
  normal: 'create',
  supermarket: 'create-supermarket',
};

export const PARTY_TYPES = {
  is_not_supermarket: 0,
  is_supermarket: 1,
};

export const COST_STATUSES = {
  pedding: 1,
  approved: 2,
  paid: 3,
  cancel: 4,
};

export const TYPE_CREATE_ORDER_GIFT = {
  giftIn: 0,
  giftOut: 1,
};

export const DEFAULT_PAYMENT_METHOD = { id: 2, name: 'Chuyển khoản cá nhân' };

export const ROLE_NAME = {
  warehouse: 'warehouse',
  ship: 'shipping',
  admin: 'admin',
  superAdmin: 'super admin',
};

export const SETTINGS = {
  sendFast: 1,
};
